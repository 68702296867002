<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Start Time -->
        <template #cell(startDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.startDate
          }}</span>
        </template>

        <!-- End Time -->
        <template #cell(endDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.endDate
          }}</span>
        </template>

        <!-- End Time -->
        <template #cell(class)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.classes.map(function(elem){
                return elem.class.name;
            }).join(',')
          }}</span>
        </template>

        <!-- Actions -->
        <template #cell(start)="data">
            <b-button variant="flat-success" @click="startLiveLesson(data.item)">
                <feather-icon icon="PlayIcon" class="mr-50" />
            </b-button>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-danger" @click="deleteLiveLesson(data.item.id)">
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span class="align-middle">Sil</span>
            </b-button>
            <b-button variant="flat-warning" @click="editLiveLesson(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Düzenle</span>
            </b-button>
        </template>
    </b-table>

    <!--Open Add LiveLesson-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addLiveLessonPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Canlı Ders Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Sınıf/Ders " label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(video) => video.id" :options="userLessons" :getOptionLabel="option => option.lesson.name+' / '+option.class.name" v-model="parentClassId" />
                </b-form-group>
                <b-form-group label="Alt Sınıf" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(video) => video.class.id" :options="childClass" :getOptionLabel="option => option.class.name" multiple v-model="addLiveLessonRequest.userClassId" />
                </b-form-group>
                <b-form-group label="Canlı Ders Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addLiveLessonRequest.code" />
                </b-form-group>
                <b-form-group label="Canlı Ders Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addLiveLessonRequest.name" />
                </b-form-group>
                <b-form-group label="Canlı Ders Açıklaması" label-for="name">
                    <b-form-textarea id="textarea-default" v-model="addLiveLessonRequest.definition" rows="3" />
                </b-form-group>
                <b-form-group label="Canlı Ders Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="addLiveLessonRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true}" />
                </b-form-group>
                <b-form-group label="Canlı Ders Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="addLiveLessonRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true}" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addLiveLesson">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <!--Open Add LiveLesson-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editLiveLessonPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Canlı Ders Düzenle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Canlı Ders Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editLiveLessonRequest.code" />
                </b-form-group>
                <b-form-group label="Canlı Ders Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editLiveLessonRequest.name" />
                </b-form-group>
                <b-form-group label="Canlı Ders Açıklaması" label-for="name">
                    <b-form-textarea id="textarea-default" v-model="editLiveLessonRequest.definition" rows="3" />
                </b-form-group>
                <b-form-group label="Canlı Ders Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="editLiveLessonRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Canlı Ders Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="editLiveLessonRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editLiveLessonRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateLiveLesson">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        flatPickr,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'startDate', label: 'Başlama Tarihi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'code', label: 'Canlı Ders Kodu' },
                { key: 'name', label: 'Canlı Ders Adı' },
                { key: 'definition', label: 'Canlı Ders Açıklaması' },
                { key: 'class', label: 'Sınıflar' },
                { key: 'start', label: 'Başlat' },
                { key: 'actions', label: 'İşlemler' },
            ],
            users: [],
            addLiveLessonPanel: false,
            editLiveLessonPanel: false,
            parentClassId: "",
            addLiveLessonRequest: {
                userClassId: 0,
                code: '',
                name: '',
                startDate: '',
                endDate: '',
                definition: ''
            },
            editLiveLessonRequest: {
                liveLessonId: 0,
                code: '',
                name: '',
                startDate: '',
                endDate: '',
                definition: '',
                isActive: false
            },
            userLessons: [],
            childClass: []
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addLiveLessonPanel = true;
        });
    },
    created() {
        this.getData();
        this.getLesson();
    },
    watch: {
        parentClassId: function (val) {
            var filterlesson = this.userLessons.filter(x => x.id == val)[0];
            this.childClass = filterlesson.childClass;
        }
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Teacher/LiveLesson");
            this.users = users.data.data;
        },
        async getLesson() {
            var userLessons = await this.$http.get("Teacher/Lessons");
            this.userLessons = userLessons.data.data;
        },
        async startLiveLesson(data) {
            var liveLesson = await this.$http.get("Teacher/RewriteLiveLesson/" + data.id);
            data = liveLesson.data.data;
            window.open(data.teacherLink, "_blank");
        },
        async deleteLiveLesson(id) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteLiveLesson/" + id).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Canlı Ders Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        },
        editLiveLesson(data) {
            this.editLiveLessonRequest = {
                liveLessonId: data.id,
                code: data.code,
                name: data.name,
                startDate: data.startDate,
                endDate: data.endDate,
                definition: data.definition,
                isActive: data.isActive
            }
            this.editLiveLessonPanel = true;
        },
        addLiveLesson() {
            this.addLiveLessonRequest.videoGroupId = this.$route.params.videoGroupId;
            var request = new FormData();
            request.append("videoGroupId", this.addLiveLessonRequest.videoGroupId);
            request.append("code", this.addLiveLessonRequest.code);
            request.append("name", this.addLiveLessonRequest.name);
            request.append("startDate", this.addLiveLessonRequest.startDate);
            request.append("endDate", this.addLiveLessonRequest.endDate);
            request.append("definition", this.addLiveLessonRequest.definition);
            var index = 0;
            this.addLiveLessonRequest.userClassId.forEach(element => {
                request.append("userClassId[" + index + "]", element);
            });

            this.$http.post("Teacher/AddLiveLesson", request).then((data) => {
                    this.getData();
                    this.addLiveLessonPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Canlı Ders Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateLiveLesson() {
            var request = this.editLiveLessonRequest;
            this.$http.put("Teacher/UpdateLiveLesson", request).then((data) => {
                    this.getData();
                    this.editLiveLessonPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Canlı Ders Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
